const staticGifs = [
  "static1",
  "static2",
  "static3",
  "static4",
  "static5",
  "static6",
  "static7",
  "static8",
];

export default staticGifs;
