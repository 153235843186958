const stations = [
  {
    id: "AzwgsZUcTfM",
    name: "Autumn Chill 🍂 Refresh Your Mind 🍂 24/7 Lofi hiphop",
  },
  {
    id: "1wckb-eWOxw",
    name: "jazz/lofi hip hop radio🌱chill beats to relax/study to [LIVE 24/7]",
  },

  {
    id: "5yx6BWlEVcY",
    name: "Chillhop Radio - jazzy & lofi hip hop beats",
  },

  {
    id: "lP26UCnoH9s",
    name: "coffee shop radio // 24/7 lofi hip-hop beats",
  },

  {
    id: "7NOSDKb0HlU",
    name: "lofi hip hop radio - beats to study/relax to 🐾",
  },

  {
    id: "tNkZsRW7h2c",
    name: "Space Ambient Music LIVE 24/7: Space Traveling Background Music, Music for Stress Relief, Dreaming",
  },
  {
    id: "apCom1TeTiA",
    name: "24/7 lofi hip hop radio - beats to study/chill/relax",
  },

  {
    id: "qH3fETPsqXU",
    name: "【24/7 CHILL LOFI HIP HOP RADIO】beats to sleep/relax/study to",
  },
];

export default stations;
